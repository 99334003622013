import axios from "axios";
import ElementUI from 'element-ui';
import router from '../router';

const instance = axios.create({
    // baseURL: "https://www.esenmart.com/api.php",
    baseURL: "https://server.esenmart.com/api.php", //线上
    // baseURL: "https://devapi.esenmart.com/api.php",  //开发
    // baseURL: "http://192.168.31.57/api.php",
    // baseURL: "http://chunshan.zzrong.online/api.php", //本地
});


// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        // console.log('config---------',config)
        // 在发送请求之前做些什么
        // 在请求头中添加Authorization头部
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.token = `${token}`;
        }
        config.headers['language'] = 'en';
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error.data);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        // console.log(response)
        if (response.status === 200) {
            if (response.data.code === 1) {
                return Promise.resolve(response.data);
            } else {
                ElementUI.Message({
                    message: response.data.msg,
                    type: 'warning'
                });
                return Promise.reject(response.data);
            }
        } else if (response.status === 401) {
            // window.location.href = "/login";
            router.push('/login')
        } else {
            ElementUI.Message({
                message: 'Network error, please try again later',
                type: 'warning'
            });
            return Promise.reject(response.data);
        }
    },
    function (error) {
        console.log("error")

        if (error.response.status === 401) {
            // window.location.href = "/login";
            router.push('/login')
        }
        // 对响应错误做点什么
        return Promise.reject(error.data);
    }
);

export default instance;