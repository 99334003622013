import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
import locale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包

Vue.use(ElementUI, { locale });

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);


import i18n from './components/i18n'
import './static/font/font.css'

import translate from 'i18n-jsautotranslate'
Vue.prototype.$translate = translate;

import api from "./api/api.js";

Vue.prototype.$api = api;


Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')

Vue.filter('dateFormatYmd', function(originVal, timeZone = 'Asia/Tokyo'){
    if (!originVal) {
        return '-';
    }

    // 先把传参毫秒转化为new Date()
    const date = new Date(originVal * 1000);
    return new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit',
        hour12: false,
        timeZone: timeZone
    }).format(date);
})

Vue.filter('dateFormat', function(originVal, timeZone = 'Asia/Tokyo'){
    if (!originVal) {
        return '-';
    }

    const date = new Date(originVal * 1000 );
    //格式输出 //ja-JP-u-ca-japanese
    return new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: timeZone
    }).format(date);
})